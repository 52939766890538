export const CAL_MAX_FEATURES = [
  {
    title: 'All-in-One Organization',
    text: 'Stay effortlessly organized with a touchscreen display that shows all your events, chores, dinner plans, and tasks.',
    alt: 'Touchscreen digital calendar display showing organized events, chores, and dinner plans for multiple dates, including family tasks and scheduled activities for March 22, April 7, and April 12',
    plus: false,
  },
  {
    title: 'Calendar Auto-Syncing',
    text: 'Seamlessly connect every calendar from multiple personal devices for total schedule clarity.',
    alt: 'Skylight Calendar displaying a synced schedule from multiple platforms including Google, Outlook, Yahoo, and Cozi, showcasing seamless integration for total schedule clarity',
    plus: false,
  },
  {
    title: 'Anytime, Anywhere Planning',
    text: 'With the free Skylight app, you can easily update your schedule, chore chart, lists, and more on the go.',
    alt: 'A woman using the Skylight app to manage tasks such as walking the dog and answering emails',
    plus: false,
  },
  {
    title: 'Sidekick Smart Family Assistant',
    text: "Magically turn emails, paper schedules, and PDFs into Calendar events with Sidekick. It'll even write you a meal plan to suit any family's preferences!",
    alt: 'A women using the Sidekick Smart Family Assistant to turn emails, paper schedules, and PDFs into Calendar events, showcasing the ability to write a meal plan to suit any family’s preferences',
    plus: true,
  },
  {
    title: 'Interactive Chore Chart',
    text: 'Manage daily chores while encouraging healthy habits and building independence.',
    alt: 'Interactive chore chart displaying daily tasks with checkmarks for two out of four chores completed, homework and take trash out.',
    plus: false,
  },
  {
    title: 'Star-Powered Rewards',
    text: 'Keep tabs on progress with a tap! Add stars to Chores to motivate kids to complete tasks by earning Rewards.',
    alt: `A child earning stars for completing chores like clean room, showcasing the ability to add stars to Chores to motivate kids to complete tasks by earning Rewards.`,
    plus: true,
  },
  {
    title: 'Meal Planning, Simplified',
    text: 'Plan a week’s worth of meals in 5 minutes. Save family favorites, or ask Sidekick to suggest something to fit even the pickiest palates.',
    alt: `Family adding meals like Homemade Pizzza and Breakfast Burittos to the Skylight Calendar's Meal Plan view.`,
    plus: true,
  },
  {
    title: 'Custom Lists',
    text: 'Grocery lists, packing lists, wish lists — you can create, edit, and color code them right from the Calendar or on the go with the app.',
    alt: 'Custom lists for groceries, to-dos, and birthday wish lists displayed on a digital screen, showcasing the ability to create, edit, and color-code lists using the Skylight app',
    plus: false,
  },
]
