import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const ContentContainer = styled.div`
  margin: 50px 0px 100px;
  position: relative;
  .container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: auto;
    max-width: 1100px;
  }

  .left-column section {
    margin: 60px 0px;
  }

  .right-column {
    position: relative;
  }

  .active-image {
    opacity: 1;
    visibility: visible;
  }
  .active-section {
    opacity: 1;
  }
  .inactive-section {
    color: #bdbdbd;
    opacity: 0.5;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 20px 0px 40px;
    .container {
      grid-template-columns: 1fr;
    }
    .right-column {
      display: none;
    }
    .inactive-section {
      color: #000;
      opacity: 1;
    }
    .left-column section {
      margin: 40px 0px;
    }
  }
`
export const Header = styled.h1`
  font-family: 'FilsonPro';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: black;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    margin: 0px;
  }
`

const DesktopContainer = css`
  position: fixed;
  top: 40%;
  left: 70%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  width: ${(props) => (props.highest ? '480px' : '400px')};
  @media (max-width: 1500px) {
    top: 45%;
    width: ${(props) => (props.highest ? '400px' : '350px')};
  }
  @media (max-width: 1350px) {
    width: ${(props) => (props.highest ? '400px' : '320px')};
  }
  @media (max-width: ${breakpoints.xl}px) {
    left: 75%;
  }
`

export const ImageContainer = styled.object`
  ${DesktopContainer}
`
export const MealImageContainer = styled.div`
  ${DesktopContainer}
`

export const Title = styled.p`
  font-family: 'FilsonPro';
  font-size: 24px;
  font-weight: 500;
  margin: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`
export const CalTitle = styled(Title)`
  align-self: baseline;
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 16px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 22px;
  }
`
export const MobileImageContainer = styled.object`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 80%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`
export const PlusBubble = styled.div`
  border-radius: 26px;
  background-color: ${(props) => (props.blue ? '#DBE9F2' : '#fff')};
  display: inline-block;
  padding: 3px 16px;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 12px;
  }
`
export const BubbleText = styled.h4`
  padding: 2px 0px 0px;
  margin: 0px;
  font-family: 'P22MackinacProMedium';
  font-size: 17px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
  }
`
export const MealImage = styled(GatsbyImage)`
  width: 360px;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`
